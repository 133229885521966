import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled, { createGlobalStyle } from "styled-components";
import Logo from "./components/Logo";
import CategoryPage from "./pages/CategoryPage";
import HomePage from "./pages/HomePage";
import WelcomePage from "./pages/WelcomePage";
import VideoPage from "./pages/VideoPage";
import SpeechPage from "./pages/SpeechPage";
import { responsiveAt } from './constants/responsive'
import './styles/page_styles.css';

const routes = [
  { path: '/', Component: WelcomePage },
  { path: '/uvod', Component: SpeechPage },
  { path: '/glasbila', Component: HomePage },
  { path: '/glasbila/:category/', Component: CategoryPage },
  { path: '/glasbila/:category/video', Component: VideoPage },
  { path: '/glasbila/:category/instrument/:instrument', Component: VideoPage },
]

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    min-height: 100vh;
    overflow-y: scroll;

  }
  
  :root {
    --palette-bg: #FFFFFF;
    --palette-txt: #000000;
    --palette-1: #602D91;
    --palette-2: #0A286C;
    --palette-3: #AA9066;
    --palette-bg-transparent: #0000000A;
    --cat-card-text-idle: var(--palette-bg);
    --cat-card-text-hover: var(--palette-bg);
    --cat-card-idle: var(--palette-1);
    --cat-card-hover: var(--palette-2);
  }

  html {
    font-family: 'Karla', sans-serif;
    scroll-behavior: smooth;
  }

  @media only screen and (max-width: ${responsiveAt}) {
    .page {
      padding-bottom: 3em;
    }
  }  
`

function App() {
  return (
    <div className="App">
      <GlobalStyles />

      <BrowserRouter>
        <Container className="container"  >

          <Logo></Logo>
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page">
                    <Component />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
        </Container>
      </BrowserRouter>

      <AppStyles.Preload>
        <img src="/media/logo-small.png" alt="preload" />
        <img src="/media/logo-big.png" alt="preload" />
      </AppStyles.Preload>
    </div>
  );
}

export default App;

const AppStyles = {
  Preload: styled.div`
    display: none;
    pointer-events: none;
  `
}
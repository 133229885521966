import React, { ReactElement } from 'react'
import { useHistory } from 'react-router'
import { BiArrowBack } from 'react-icons/bi';
import Card from './Card';


interface Props {
  action?: () => any
}

export default function BackButton({action}: Props): ReactElement {

  const history = useHistory();
  const goBack = () => history.goBack();

  function handleAction(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if(action) action();
    goBack();
  }

  return (
 
      <Card Icon={BiArrowBack} path={undefined} reducedPadding onClickOverride={handleAction} >Nazaj</Card>
  )
}

import React, { ReactElement } from 'react'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Instrument } from '../api/fixed'

type CardProps = {
  children: any;
  path?: string;
  reducedPadding?: boolean;
  Icon?: IconType
  onClickOverride?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Card({children, path, reducedPadding, Icon, onClickOverride}: CardProps): ReactElement {
  return (
      <CardStyles.Root  >
        {/* @ts-expect-error: Force the path to be undefined, this allows the onClick override to work */}
        <CardStyles.Anchor onClick={onClickOverride} reducedPadding={reducedPadding ?? false} to={path}  >
          <CardStyles.Content>
            {Icon &&
              <>
                <Icon size="35" ></Icon>
                &nbsp;&nbsp;
              </>
            }
            {children}
            &nbsp;
          </CardStyles.Content>
        </CardStyles.Anchor>

        {/* <CardStyles.Flag>
          <div></div>
          <div></div>
          <div></div>
        </CardStyles.Flag> */}
      </CardStyles.Root>
  )
}

const CardStyles = {
  Root: styled.div`
    position: relative;
    
    width: 100%;
    height: 100%;

    transition: all 0.2s ease;
    transition-property: color, background-color, box-shadow;
    background-color: transparent;

    background-color: var(--cat-card-idle);
    color: var(--cat-card-text-idle);
    font-size: 1.3rem;

    text-transform: uppercase; 
    overflow: hidden;

    & div  {
      transition: transform 0.2s ease;
    }

    &:hover {
      color: var(--cat-card-text-hover);
      background-color: var(--cat-card-hover);
      box-shadow: inset 0 0 0 4px white;

      & div  {
        transform: translateX(8px);
      }
    }
   
  `,
  Anchor: styled(Link)<{reducedPadding: boolean}>`
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: ${props => props.reducedPadding ? '15px 16px' : '35px 26px' };

    text-decoration: none;
    color: inherit;
  `,
  Content: styled.div`
    height: 100%;

    display: flex;
    align-items: center;
  `,
  Flag: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    
    & div {
      flex: 1;
    }

    & div {
      &:nth-child(1) { 
        background-color: var(--palette-1);
      }
      
      &:nth-child(2) {
        background-color: var(--palette-2);
      }
      
      &:nth-child(3) {
        background-color: var(--palette-3);
      }
    }
    z-index: -1;
  `, 
}
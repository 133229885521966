import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface Props {
  children: any;
}

export default function VideoContainer({children}: Props): ReactElement {
  return (
    <VideoContainerStyles.Root>
      <VideoContainerStyles.IFrameContainer>
        {children}
      </VideoContainerStyles.IFrameContainer>
    </VideoContainerStyles.Root>
  )
}

// Much love to
// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed

const VideoContainerStyles = {
  Root: styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  `,
  IFrameContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // This fills in the background while the iframe is still loading
    background-color: black;

    & > div {
      width: 100%;
      height: 100%;
    }

    & iframe {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `
}
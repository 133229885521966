import React, { ReactElement } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  
}

export default function Logo({}: Props): ReactElement {

  let currentPath = useLocation();
  let isRoot = currentPath.pathname == '/';

  return (
    <LogoStyles.Root>
      <Link to="/" >
        {isRoot
        ?<LogoStyles.ImageBig src="/media/logo-big.png" />
        :<LogoStyles.Image src="/media/logo-small.png" />}
      </Link>
    </LogoStyles.Root>
  )
}

const LogoStyles = {
  
  Root: styled.div`
    display: grid;
    place-items: center;
    
    padding: 7px 14px;
    padding-bottom: 24px;
  `,
  Image: styled.img`
    width: 320px
  `,
  ImageBig: styled.img`
    /* width: 320px; */

    margin-top: 2em;
    max-width: 500px;
    width: 100%;
    /* max-height: 320px;; */
  `
}
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router'
import styled from 'styled-components';
import { instrumentCategories, InstrumentCategory } from '../api/fixed';
import Card from '../components/Card';
import CardGrid from '../components/CardGrid';
import Heading from '../components/Heading'
import InstrumentCategoryCard from '../components/InstrumentCategoryCard'
import { AiFillPlayCircle } from 'react-icons/ai'
import BackButton from '../components/BackButton';
import { responsiveAt } from '../constants/responsive';
import { BiArrowBack } from 'react-icons/bi';

interface Props {
  
}

export default function CategoryPage({}: Props): ReactElement {

  let routeMatch = useRouteMatch();
  let urlPrefix = routeMatch?.url
  const { category } = useParams<{category: string}>();

  let videoUrl = `${urlPrefix}/video`;
  
  // const categoryData: InstrumentCategory = useMemo<InstrumentCategory | null>(() => {
  //   if(!category && (categoryData ?? false)) return categoryData;
  //   return instrumentCategories.find((_cat: InstrumentCategory) => _cat.name == category ) ?? null
  // }, [category])

  const [categoryData, setCategoryData] = useState<InstrumentCategory | null>(null);

  useEffect(() => {
    if(category) {
      setCategoryData(instrumentCategories.find((_cat: InstrumentCategory) => _cat.name == category ) ?? null)
    }
  }, [category])


  return (
    <div  >
      <CategoryPageStyles.Header>
        <Heading>{categoryData?.label}</Heading>

        {/* {(categoryData?.videos) && <div style={{
          maxWidth: 300,
        }} >
          <Card reducedPadding path={videoUrl} Icon={AiFillPlayCircle} >
            Oglej si video
          </Card>
        </div>} */}
      </CategoryPageStyles.Header>

      <CardGrid>
        {categoryData?.instruments?.map(_inst => <Card path={`${urlPrefix}/instrument/` + _inst.name} >{_inst.label}</Card>)}

        {categoryData?.appendCategory && <InstrumentCategoryCard category={categoryData.appendCategory} />}
        
      </CardGrid>

      <CategoryPageStyles.Footer>
        {/* <BackButton action /> */}
        <div style={{
          maxWidth: 150,
        }} >
          <Card Icon={BiArrowBack} path={categoryData?.inlineRedirectTo ? categoryData?.inlineRedirectTo : '/glasbila'} reducedPadding >Nazaj</Card>
        </div>
      </CategoryPageStyles.Footer>
    </div>
  )
}
const CategoryPageStyles = {
  Header: styled.div`
    max-width: 1000px;
    margin: 0 auto;
    align-items: baseline;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${responsiveAt}) {
      flex-direction: column;
      margin-bottom: 15px;
    }
  `,

  Footer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    max-width: 1000px;

    margin: 3em auto;
    /* margin-top: 64px; */
  `,
}
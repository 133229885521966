const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate())

export type Countdown = {
  startAt: Date,
  video: Video
}

export const countdown: Countdown = {

  // When the countdown ends
  startAt: tomorrow,
  video: 'MUeIbMUj0AQ'
}


// All the info we need about a video is it's URL
export type Video = string;
export type Videos = Video[]

export type Instrument = {
  name: string;
  label: string;
  videos: Videos;
  moreAbout?: string;
}

export type InstrumentCategory = {
  name: string;
  label: string;
  instruments?: Instrument[];
  videos?: Videos;
  inline?: boolean;
  appendCategory?: InstrumentCategory;
  inlineRedirectTo?: string;
  moreAbout?: string;
}

// This one category is used 2 times because the same data has to present twice in the tree.
// This is very bad - dreadful even - but this has to be done soon and I am not refactoring the entire routing logic
// for better code, because I am already very tired *shrug*.
const orgleCat: InstrumentCategory = {
  name: 'orgle',
  label: 'Orgle',
  moreAbout: 'Več o Orglah',
  videos: [ '1JUg2FgNpIM' ],
  inline: true,
  inlineRedirectTo: '/glasbila/instrumenti_s_tipkami',
  // videos: [ '1ZS447D4R1I', 'd5I5cQTx9fw', 'H6wSKexePCY'],

  instruments: [
    {
      name: 'orgle',
      label: 'Orgle',
      videos: [ 'd5I5cQTx9fw' ]
    },
    {
      name: 'cembalo',
      label: 'Čembalo',
      videos: [ 'H6wSKexePCY' ]
    },
  ]
}

export const instrumentCategories: InstrumentCategory[] = [
  {
    name: 'najmlajsi',
    label: 'Najmlajši',
    moreAbout: 'Več o Najmlajših',
    videos: [ 'gV2rPo_dsrE', 'EgbDPj9x1-o' ]
  },
  {
    name: 'trobila',
    label: 'Trobila',
    moreAbout: 'Več o Trobilih',
    videos: [ 'fsbOJh04UQU', '93bkjFWjw1w' ]
  },
  {
    name: 'godala',
    label: 'godala',
    moreAbout: 'Več o Godalih',
    videos: [ 'MYN5LC73Ksg', 'CTSKZd6StCA' ]
  },
  {
    name: 'pihala',
    label: 'Pihala',
    moreAbout: 'Več o Pihalih',
    videos: [ 'bOG7-i1UcH4' ],
    // videos: [ '3X0ZZ_Arthg', 'FkpzW8Hifzk', '09aVHwnU1qY', 'hW5i3Uo4bCg', 'v9Hjdo6uHKM' ],

    instruments: [
      {
        name: 'kljunasta_flavta',
        label: 'kljunasta flavta',
        videos: [ '3X0ZZ_Arthg' ]
      },
      {
        name: 'flavta',
        label: 'flavta',
        videos: [ 'FkpzW8Hifzk' ]
      },
      {
        name: 'klarinet',
        label: 'Klarinet',
        videos: [ '09aVHwnU1qY' ]
      },
      {
        name: 'saksofon',
        label: 'saksofon ',
        videos: [ 'hW5i3Uo4bCg' ]
      },
      {
        name: 'oboa_in_fagot',
        label: 'Oboa in Fagot',
        videos: [ 'v9Hjdo6uHKM' ]
      },
    ]
  },
  {
    name: 'petje',
    label: 'petje',
    moreAbout: 'Več o Petju',
    videos: [ 'T22rMshwDFU','GgfemoHoMCc' ]
  },
  {
    name: 'instrumenti_s_tipkami',
    label: 'INŠTRUMENTI S TIPKAMI',
    moreAbout: 'Več o Inštrumentih s tipkami',

    instruments: [
      {
        name: 'klavir',
        label: 'Klavir ',
        moreAbout: 'Več o Klavirju',
        videos: [ 'noyjR8zvSAc', 'EUxRC7nb_5w' ]
      },
      {
        name: 'harmonika',
        label: 'Harmonika',
        moreAbout: 'Več o Harmoniki',
        videos: [ 's1Xb3j7vhcI', 'YUjvqHY8oVk' ]
      },
    ],

    appendCategory: orgleCat

    
  },
  orgleCat,
  {
    name: 'brenkala',
    label: 'Brenkala',
    moreAbout: 'Več o Brenkalah',

    instruments: [
      {
        name: 'citre',
        label: 'Citre ',
        moreAbout: 'Več o Citrah',
        videos: [ 'b5v_o7QMAlw', 'RsTrb-Ph1Zs' ]
      },
      {
        name: 'kitara',
        label: 'Kitara',
        moreAbout: 'Več o Kitari',
        videos: [ '1qDXchC2GVg', 'SHTGCmftk2w' ]
      },
      {
        name: 'harfa',
        label: 'Harfa',
        moreAbout: 'Več o Harfi',
        videos: [ 'yZxDqRwO6es', 'rYT4KnyBYQM' ]
      },
    ]
  },
  {
    name: 'tolkala',
    label: 'TOLKALA',
    moreAbout: 'Več o Tolkalih',
    videos: [ 'EIjqMW1EpEY', 'y_brZ_RWyes' ]
  },
  {
    name: 'balet',
    label: 'BALET',
    moreAbout: 'Več o Baletu',
    videos: [ 'Gx_2ynB6TRs', 'QtCVfYurg38' ]
  },
  {
    name: 'zbor',
    label: 'ZBOR',
    moreAbout: 'Več o Zboru',
    videos: [ 'mqaHEAJSpFA', 'dLIXJ8u_9e4' ]
  },
  {
    name: 'oddelek_sostanj',
    label: 'Oddelek Šoštanj',
    videos: [ 'uhw22szfYSs' ]
  },
  {
    name: 'oddelek_smartno',
    label: 'Oddelek Šmartno',
    videos: [ 'GJtZK-zPu4U' ]
  },
]
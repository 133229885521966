import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { instrumentCategories } from '../api/fixed'
import Card from '../components/Card'
import CardTrueAnchor from '../components/CardTrueAnchor'
import CardGrid from '../components/CardGrid'
import InstrumentCategoryCard from '../components/InstrumentCategoryCard'
import { responsiveAt } from '../constants/responsive'
import TestLink from '../components/TestLink'

interface Props {
  
}

export default function HomePage({}: Props): ReactElement {
  return (<>
    <CardGrid>
      {instrumentCategories.map(_ic => {
      if(_ic.inline) return null;
      return <InstrumentCategoryCard category={_ic} />
    })}
    </CardGrid>

    <HomePageStyles.Controls >
      <div>
        <Card path="/uvod" reducedPadding >Uvod</Card>
        <CardTrueAnchor href="https://www.eglasbenasola.si/applications_api/application/26" reducedPadding >Vpis in Prijavnica</CardTrueAnchor>
        <TestLink />
        <CardTrueAnchor href="https://www.youtube.com/channel/UCbDgoWALMbLIdlTfqA9w_WQ" reducedPadding >Naš Youtube kanal</CardTrueAnchor>
      </div>
    </HomePageStyles.Controls>

    <div style={{height: '3em'}} ></div>
  </>)
}

const HomePageStyles = {
  Controls: styled.div`

    margin: 0 auto;
    max-width: 1000px;
    display: flex;
    margin-top: 3em;
    gap: 20px;

    & > div {
      --cat-card-idle: var(--palette-bg-transparent);
      --cat-card-text-idle: var(--palette-txt);
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @media only screen and (max-width: ${responsiveAt}) {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  `,
 
}
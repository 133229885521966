import React, { ReactElement, useEffect, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { CountdownProps } from 'react-countdown/dist/LegacyCountdown';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { useHistory } from 'react-router';
import YouTube from 'react-youtube';
import styled from 'styled-components'
import { countdown } from '../api/fixed';
import Card from '../components/Card';
import CardTrueAnchor from '../components/CardTrueAnchor';
import Heading from '../components/Heading';
import TestLink from '../components/TestLink';
import VideoContainer from '../components/VideoContainer';
import { responsiveAt } from '../constants/responsive';
import { youtubeOptions } from '../constants/youtubeOptions';

interface Props {
  
}

function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export default function SpeechPage({}: Props): ReactElement {


  // Only have the button appear after one minute
  // Why not just show it? because... yeah idk
  const [showSignUp, setShowSignUp] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowSignUp(true), 60 * 1000);
  }, [])

  const history = useHistory();

  // Redirect the user as soon as the video ends
  function handleOnEnd() {
    history.push('/glasbila');
  }

  return (
    <WelcomeStyles.Root>

      <VideoContainer>
        <YouTube 
          videoId={countdown.video}
          opts={{
            playerVars: youtubeOptions
          }}
          onEnd={handleOnEnd}
        />
      </VideoContainer>

      <WelcomeStyles.Controls  >
        <Card onClickOverride={topFunction} path="/glasbila" Icon={AiOutlineArrowRight} reducedPadding >Vstopi v glasbeno šolo</Card>
        {showSignUp && <CardTrueAnchor Icon={BsBoxArrowUpRight} href="https://www.gsvelenje.si/sl/p/arhiv-novic/nov/627-termini-sprejemnih-preizkusov-za-vpis-novih-ucencev/" reducedPadding >Sprejemni preizkus</CardTrueAnchor>}
      </WelcomeStyles.Controls>
    </WelcomeStyles.Root>
  )
}

const WelcomeStyles = {
  Root: styled.div`
    max-width: 1000px;
    margin: 0 auto;
    min-height: 70vh;
    

    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 2.5em;

      @media only screen and (max-width: ${responsiveAt}) {
        font-size: 1.5rem;
      }
    }

    h1 {
      font-size: 3em;

      @media only screen and (max-width: ${responsiveAt}) {
        font-size: 2rem;
      }
    };

    padding-bottom: 3em;
  `,
  IFrameContainer: styled.div`
    flex: 1;
    width:100%;
    object-fit: fill;
    // This fills in the background while the iframe is still loading
    background-color: black;

    display: flex;
      flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;

      iframe {
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }

    @media only screen and (max-width: ${responsiveAt}) {
      max-width: 100%;
      /* height: 80%; */
    }
  `,
  Controls: styled.div`

   /* width: 100%; */
    display: flex;
    justify-content: center;
    gap: 20px;
    
    margin-top: 20px;

    @media only screen and (max-width: ${responsiveAt}) {
      flex-direction: column;

      // Safari is so bad, it supports the gap property but it's 
      // just broken half the time
      gap: 0;
      & > * {
        margin-bottom: 20px;
      }
    }

  `
}
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Instrument, InstrumentCategory } from '../api/fixed'
import Card from './Card';

type InstrumentCategoryCardStylesProps = {
  category: InstrumentCategory;
};

export default function InstrumentCategoryCard({category}: InstrumentCategoryCardStylesProps): ReactElement {

  // Redirect to the category video (if there is one) if the category doesn't have
  // any listend instruments
  // let bInstantlyRedirectToVideo = category?.videos && !category?.instruments;
  // let path = bInstantlyRedirectToVideo
  //   ? `/glasbila/${category.name}/video`
  //   : `/glasbila/${category.name}`

  let bInstantlyRedirectToVideo = category?.videos;
  let path = bInstantlyRedirectToVideo
    ? `/glasbila/${category.name}/video`
    : `/glasbila/${category.name}`

  return (
    <div>
      <Card path={path} >
        {category.label}  
      </Card>      
    </div>
  )
}
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import { Instrument, instrumentCategories, InstrumentCategory, Videos } from '../api/fixed'
import Card from '../components/Card'
import { BiArrowBack } from 'react-icons/bi';
import BackButton from '../components/BackButton'
import { AiOutlineForward } from 'react-icons/ai';
import { responsiveAt } from '../constants/responsive'
import CategoryPage from './CategoryPage'
import { youtubeOptions } from '../constants/youtubeOptions'
import VideoContainer from '../components/VideoContainer'

interface Props {
  
}

type URLParams = {
  category: string,
  instrument?: string
}

export default function VideoPage({}: Props): ReactElement {

  const params = useParams<URLParams>();
  let routeMatch = useRouteMatch();
  let urlPrefix = routeMatch?.url
  const history = useHistory();

  const [counter, setCounter] = useState(0);
  const [showNext, setShowNext] = useState(false);
  // const [showNextInstruments, setShowNextInstruments] = useState(false);

  const [shouldAutomaticallyGoBack, setShouldAutomaticallyGoBack] = useState(true);
  const [goBackTimeout, setGoBackTimeout] = useState<NodeJS.Timeout>();
  const [minuteTimeout, setMinuteTimeout] = useState<NodeJS.Timeout>();

  // Used at the end of the last video
  function startTimeout() {
    if(goBackTimeout) return;
    setGoBackTimeout(setTimeout(() => history.goBack(), 1000));
  }

  // Clear the goBackTimeout if the user manualy goes back before the timeout could ever happen
  function clearGoBackTimeout() {
    
    if(goBackTimeout) {
      console.log('CLEARING TIMEOUT ' + goBackTimeout);
      clearTimeout(goBackTimeout);
    }
  }

  // Whenever a video starts playing, set off a timer that always takes one minute to automatically show 
  // the NextVideo button
  //
  // ... Why not just always show the button? Good question.
  useEffect(() => {

    if(minuteTimeout) {
      clearTimeout(minuteTimeout);
    }

    setMinuteTimeout(
      setTimeout(() => {
        if(counter < videos.length - 1)
          setShowNext(true);
      }, 50 * 1000)
    )
  }, [counter])

  function handleOnEnd() {

    if(showInstrumentNext) return;

    if(counter < videos.length - 1)
      setShowNext(true);
    else startTimeout();
    // else shouldAutomaticallyGoBack && history.goBack();
  }

  function handleShowNextVideo(e: React.ChangeEvent<HTMLInputElement>) {

    // Fix for routing undefined paths
    e.preventDefault();

    if(counter < videos.length - 1) {
      setCounter(_value => _value + 1);

      // Hide the WATCH NEXT button until the new video ends
      setShowNext(false);
      // setShowNextInstruments(false);
    }
  }

  // Determine whether we're playing category videos or instrument videos
  let { category: pCategory, instrument: pInstrument } = params;

  const videos = useMemo<Videos>(() => {
    let category = instrumentCategories.find((_cat: InstrumentCategory) => _cat.name == pCategory);
    if(!category) history.goBack();

    if(pInstrument) {
      let instrument = category?.instruments?.find((_inst: Instrument) => _inst.name == pInstrument);
      if(instrument) {
        return instrument.videos;
      }
    }

    if(category?.videos)
      return category?.videos;
    
    return []

  }, []);

  const thisInstrument = useMemo<Instrument | null>(() => {
    let category = instrumentCategories.find((_cat: InstrumentCategory) => _cat.name == pCategory);
    if(!category) history.goBack();

    if(pInstrument) {
      let instrument = category?.instruments?.find((_inst: Instrument) => _inst.name == pInstrument);
      if(instrument) {
        return instrument;
      }
    }

    return null;
  }, []);

  // If the category has both videos and instruments, allow the user to redirect to the videos page whenever
  // This also has some other side effects - if true:
  // - Stop the auto redirect
  // - Always show a Continue button next to the Back button
  let thisCat = instrumentCategories.find((_cat: InstrumentCategory) => _cat.name == pCategory);
  const showInstrumentNext = thisCat?.videos && thisCat.instruments && !pInstrument;

  // const instruments = useMemo<Instrument[]>(() => {
  //   let category = instrumentCategories.find((_cat: InstrumentCategory) => _cat.name == pCategory);
  //   if(!category) history.goBack();

  //   console.log(category?.instruments);
    

  //   if(category?.instruments) {
  //     setShouldAutomaticallyGoBack(false);
  //     return category.instruments;
  //   }
    
  //   return []
  // }, []);


  return (
    <VideoPageStyles.Root>
      <VideoContainer>
        <YouTube
          videoId={videos[counter]}
          opts={{
            playerVars: youtubeOptions
          }}
          onEnd={handleOnEnd}
          
        />
      </VideoContainer>

      <VideoPageStyles.Controls>

        <BackButton action={() => clearGoBackTimeout()} />

        {/* This button only appears towards the end of the video playback if there's more videos */}
        {(showNext && !showInstrumentNext) && <Card onClickOverride={handleShowNextVideo} reducedPadding Icon={AiOutlineForward}>
          {(pInstrument ? thisInstrument?.moreAbout : thisCat?.moreAbout) ?? 'NAPREJ'}
          {/* {thisInstrument?.moreAbout ?? 'NAPREJ A '} */}
        </Card>}

        {(showInstrumentNext) && <Card path={`/glasbila/${thisCat?.name}`} reducedPadding Icon={AiOutlineForward}>
          {thisCat?.moreAbout ?? 'NAPREJ'}
        </Card>}

        {/* {(showNextInstruments) && !pInstrument &&  <>
          {instruments.map(_inst => <Card   reducedPadding path={`/glasbila/${pCategory}/instrument/${_inst.name}`} >{_inst.label}</Card>)}
        </>} */}

      </VideoPageStyles.Controls>
    </VideoPageStyles.Root>
  )
}

const VideoPageStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
    /* background-color: red; */
  `,
  IFrameContainer: styled.div`
    flex: 1;
    /* width: 60%; */
    /* height: 70%; */
    width:100%;
    aspect-ratio: 16/9;

    // This fills in the background while the iframe is still loading
    background-color: black;

    display: flex;
      flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      /* width: 100%; */
      height: 100%;
      flex: 1;

      iframe {
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }

    @media only screen and (max-width: ${responsiveAt}) {
      width: 100%;
      height: 80%;
    }
  `,
  Controls: styled.div`
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media only screen and (max-width: ${responsiveAt}) {
      flex-direction: column;
      width: 100%;

      // Safari is so bad, it supports the gap property but it's 
      // just broken half the time
      gap: 0;
      & > * {
        margin-bottom: 20px;
      }
    }


  `
}
import { ReactElement } from 'react'
import styled from 'styled-components'

type CardGridProps = {
  children: any;
}

export default function CardGrid({children}: CardGridProps): ReactElement {
  return (
    <CardGridStyles.Root>
      {children}
    </CardGridStyles.Root>
  )
}

const CardGridStyles = {
  Root: styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    gap: 23px;

    max-width: 1000px;
    margin: 0 auto;

    font-size: 25px;

    & > div{
      /* & { --cat-card-hover: var(--palette-1) !important; }
      &:nth-child(3n -1) { --cat-card-hover: var(--palette-2) !important; }
      &:nth-child(3n) { --cat-card-hover: var(--palette-3) !important; } */
    }
  `
}
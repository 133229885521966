import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Instrument } from '../api/fixed'
import { responsiveAt } from '../constants/responsive'

type HeadingProps = {
  children: any;
}

export default function Heading({children}: HeadingProps): ReactElement {
  return (
      <HeadingStyles.Root>
        <HeadingStyles.Heading>
          {children}
        </HeadingStyles.Heading>
      </HeadingStyles.Root>
  )
}

const HeadingStyles = {
  Root: styled.div`
    flex: 1;
  `,
  Heading: styled.h1`
    font-size: 4rem;
    /* text-align: center; */
    text-transform: uppercase;
    margin: 1em 1em 1em 0; 

    @media only screen and (max-width: ${responsiveAt}) {
      font-size: 2rem;
      margin: 1em 1em 1em 0; 
    }
  `
}
import React, { ReactElement } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { CountdownProps } from 'react-countdown/dist/LegacyCountdown';
import { AiOutlineArrowRight } from 'react-icons/ai';
import YouTube from 'react-youtube';
import styled from 'styled-components'
import { countdown } from '../api/fixed';
import Card from '../components/Card';
import Heading from '../components/Heading';
import { responsiveAt } from '../constants/responsive';

interface Props {
  
}

function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export default function WelcomePage({}: Props): ReactElement {
  return (
    <WelcomeStyles.Root>
      {/* <Countdown date={(Date.now() + 5000) ?? countdown.startAt} autoStart intervalDelay={0} renderer={renderer} /> */}

      {/* <div>
      <h2>DAN ODPRTIH VRAT Glasbene šole Velenje</h2>
        <br />
        <p>Dobrodošli na virtualnem dnevu odprtih vrat. Za vas smo pripravili zanimivo dogodivščino in potovanje skozi Glasbeno šolo. Tako boste dobili predstavo, kaj vse se skriva za našimi vrati.</p>
        <br />
        <p>V kolikor ste pripravljeni - kliknite na gumb ZAČNI in se nam pridružite. </p>
        <br />
      </div> */}
{/* 
      <WelcomeStyles.IFrameContainer>
        <YouTube 
          videoId={countdown.video}
          opts={{
            playerVars: {
              autoplay: 1,
            }
          }}
        />
      </WelcomeStyles.IFrameContainer> */}

      <div style={{ marginTop: '2em', flex: 1, width: '100%', display: 'flex', justifyContent: 'center' }}  >
        <div style={{ display: 'inline-block' }}  >
          <Card onClickOverride={topFunction} path="/uvod" Icon={AiOutlineArrowRight} reducedPadding >ZAČNI</Card>
        </div>
      </div>
    </WelcomeStyles.Root>
  )
}

const renderer = ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (completed) {
    // Render a completed state
    return <YouTube 
      videoId={countdown.video}
      opts={{
        playerVars: {
          autoplay: 1,
        }
      }}
    />
  } else {
    // Render a countdown
    return <>
      <h2>Žačnemo čez...</h2>
      <h1>{hours}:{minutes}:{seconds}</h1>
    </>;
  }
};

const WelcomeStyles = {
  Root: styled.div`
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
    /* height: 100vh; */


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 2.5em;

      @media only screen and (max-width: ${responsiveAt}) {
        font-size: 1.5rem;
      }
    }

    h1 {
      font-size: 3em;

      @media only screen and (max-width: ${responsiveAt}) {
        font-size: 2rem;
      }
    };

    padding-bottom: 3em;
  `,
  IFrameContainer: styled.div`
    flex: 1;
    width:100%;
    aspect-ratio: 16/9;

    // This fills in the background while the iframe is still loading
    background-color: black;

    display: flex;
      flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      /* width: 100%; */
      height: 100%;
      flex: 1;

      iframe {
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }

    @media only screen and (max-width: ${responsiveAt}) {
      max-width: 100%;
      /* height: 80%; */
    }
  `,
}